'use strict';

var teasersModules = require('./teaser/teasersModules');

/* Javascript to load on page load*/
$(document).ready(function () {
	// PDP teasers only
    if ($('.product-set-detail').length > 0) {
        $('.product-set-detail').find('.set-items .container').each(function () {
            var itemContainer = $(this);
            var pid = itemContainer.data('pid');
            teasersModules.loadTeaserCounts(pid, itemContainer);
        });
    }

    var $productID = $('span.product-id');
    var productIDValue = ($productID.length > 0) ? $productID.first().text() : null;

    if (productIDValue) {
        teasersModules.loadTeaserCounts(productIDValue);
    }
});
